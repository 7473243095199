import * as React from 'react'
import { graphql } from 'gatsby';

import GeneralLayout from '../components/layouts/general'
import { blockHeaderResolver, blockResolve } from '../helpers';

const IndexPage = (props: any) => {
  const { data } = props;
  const { blocks, header, htmlTitle, metaDescription, metaTags, navBarColor, navBarStickyColor } = data.page;

  const Header = blockHeaderResolver(header.blockName[0]);

  const handleScroll = () => {
    const elBody = document.getElementsByTagName('BODY')[0]
    const elHeader = document.getElementsByClassName('header')[0]
    const elNav = document.querySelector('[data-navbar]')

    const offsetY = window.pageYOffset

    const header = elHeader?.getBoundingClientRect()
    const nav = elNav?.getBoundingClientRect()


    const navHeight = nav?.height ?? 0
    const headerHeight = header?.height ?? 0


    if (offsetY > 0) {
      elBody.classList.add('body-scrolled')
    } else {
      elBody.classList.remove('body-scrolled')
    }


    if (offsetY > navHeight) {
      elBody.classList.add('navbar-scrolled')
    } else {
      elBody.classList.remove('navbar-scrolled')
    }


    if (offsetY > headerHeight) {
      elBody.classList.add('header-scrolled')
      elNav?.classList.add('stick')
    } else {
      elBody.classList.remove('header-scrolled')
      elNav?.classList.remove('stick')
    }



  }



  React.useEffect(() => {

    window.addEventListener('scroll', handleScroll);

    (window as any).page && (window as any).page.init();

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <GeneralLayout
      htmlTitle={htmlTitle}
      metaDescription={metaDescription?.metaDescription}
      metaTags={metaTags}
      navBarColor={navBarColor}
      navBarStickyColor={navBarStickyColor}
    >
      <Header
        {...header}
      />
      {
        blocks?.map((b: any, idx: number) => {
          const B = blockResolve(b)
          return <B key={idx} {...b} />
        })
      }

      {/* <LiveDemoForm image={data.dash1} /> */}
    </GeneralLayout>
  );
}


export default IndexPage

export const pageQuery = graphql`
  query GenericPage ($slug: String){
    page: contentfulGenericPage (slug: {eq: $slug}) {
      id slug 
      htmlTitle
      metaDescription { metaDescription }
      metaTags
      navBarColor
      navBarStickyColor

      ...BlockHeaderFragment
      blocks {
        __typename
        ... on ContentfulBlockFeatureA {
          ...BlockFeatureAFragment
        }
        ... on ContentfulBlockFeatureC {
          ...BlockFeatureCFragment
        }
        ... on ContentfulBlockFeatureTextA {
          ...BlockFeatureTextAFragment
        }
        ... on ContentfulBlockService {
          ...BlockServiceFragment
        }
        ... on ContentfulBlockContent {
          ...BlockContentFragment
        }
        ... on ContentfulStaticBlock {
          ... BlockStaticFragment
        }
       
        ... on ContentfulBlockPricing {
          ... BlockPricingFragment
        }
        
        
      }
    }
  }
`